import {ExtensibleEntityDto} from './ExtensibleEntityDto'
import {PagedAndSortedResultRequestDto} from './PagedAndSortedResultRequestDto'
import {Guid} from 'guid-typescript';

export interface ShipCardOrderDto extends ExtensibleEntityDto <Guid> {
  remark: string
  price: number
  discountPrice: number
  orderState: number
  orderCode: string
}

export class GetListShipCardOrderDto extends PagedAndSortedResultRequestDto {
  public filter: string = ''
  public orderState?: number
  public isZeng?: boolean
}
