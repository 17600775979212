






























































import {Component, Vue} from 'vue-property-decorator';
import {PageResultDto} from '@/framework/models/ResultDto';
import {ShipCardOrderDto, GetListShipCardOrderDto} from '@/framework/models/ShipCardOrder';
import ShipCardOrderApi from '@/api/member/shipCardOrder';

@Component({})
export default class CardRecord extends Vue {
  private api = new ShipCardOrderApi();
  private listQuery = new GetListShipCardOrderDto();
  private tableData: PageResultDto<ShipCardOrderDto> = {
    items: new Array<ShipCardOrderDto>(),
    totalCount: 0
  };

  private pagination = {
    currentPage: 1
  }

  private async activated() {
    await this.getList();
  }

  private async currentChange(currentPage: number) {
    this.pagination.currentPage = currentPage
    this.listQuery.skipCount = (currentPage - 1) * this.listQuery.maxResultCount
    await this.getList()
  }

  private async getList() {
    this.tableData = await this.api.getListAsync(this.listQuery);
  }

  private async handleFuKuan(row: any) {
    await this.api.payment(row.id);
    await this.getList()
  }

  private getType(row: any) {
    if (row.cardType === 1) {
      return `次卡`
    } else if (row.cardType === 2) {
      return `月卡`
    } else if (row.cardType === 3) {
      return `季卡`
    } else if (row.cardType === 4) {
      return `半年卡`
    } else if (row.cardType === 5) {
      return `年卡`
    } else if (row.cardType === 6) {
      return `两年卡`
    } else if (row.cardType === 7) {
      return `家庭卡`
    }
  }

  private async handleQueryOrder(row: any) {
    const result = await this.api.queryOrder(row.id)
    this.$notify({message: result, title: ''})
  }
};
